<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 9.95"
      :y1="doorTopHeight1 + 147.88"
      :x2="doorLeftWidth1 + 15.54"
      :y2="doorTopHeight1 + 147.88"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 69.91"
      :y1="doorTopHeight1 + 237.21"
      :x2="doorLeftWidth1 + 69.91"
      :y2="doorTopHeight1 + 58.98"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f5"/>
      <stop offset="0.5" stop-color="#dde7e6"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 58.34"
      :y1="doorTopHeight1 + 274.46"
      :x2="doorLeftWidth1 + 58.34"
      :y2="doorTopHeight1 + 237.21"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 - 5966.15"
      :y1="doorTopHeight1 + 8231.44"
      :x2="doorLeftWidth1 - 5966.15"
      :y2="doorTopHeight1 + 8196.11"
      gradientTransform="translate(6140.49 -7861.02)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1868.48"
      :y1="doorTopHeight1 + 14305.81"
      :x2="doorLeftWidth1 + 1868.48" 
      :y2="doorTopHeight1 + 14268.14"
      gradientTransform="translate(-1712.9 -14130.18)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 7948.15"
      :y1="doorTopHeight1 + 6495.4"
      :x2="doorLeftWidth1 + 7948.15"
      :y2="doorTopHeight1 + 6460.07"
      gradientTransform="translate(-7834.49 -6301.02)"
      xlink:href="#handle-gradient"/>
    <g id="V06">
      <rect
        id="glass"
        class="cls-4"
        :x="glassX"
        :y="glassY"
        :width="glassWidth"
        :height="glassHeight"/>
      <g
        id="Inoxes"
        v-if="showInox">
        <rect
          id="inox"
          class="cls-5"
          :x="glassX"
          :y="inoxBY"
          width="2.21"
          :height="inoxTH"/>
        <rect
          id="inox-2"
          data-name="inox"
          class="cls-6"
          :x="inoxRX"
          :y="glassY + glassHeight - 2"
          :width="inoxLW"
          height="2.21"/>
        <rect
          id="inox-3"
          data-name="inox"
          class="cls-7"
          :x="glassX + glassWidth - 2"
          :y="inoxTY"
          width="2.21"
          :height="inoxTH"/>
        <rect
          id="inox-4"
          data-name="inox"
          class="cls-8"
          :x="inoxLX"
          :y="glassY"
          :width="inoxLW"
          height="2.21"/>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      glassWidth: 25.35,
      glassHeight: 178.23,
    }
  },
   computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxTY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxTH() {
      return this.glassY - this.inoxTY
    },
    inoxBY() {
      return this.inoxTY + this.inoxTH + this.glassHeight
    },
    inoxRX() {
      return this.inoxLX + this.inoxLW + this.glassWidth
    },
    glassX() {
      return this.doorLeftWidth + (this.doorWidth - this.glassWidth)/2
    },
    glassY() {
      return this.doorTopHeight + (this.doorHeight - this.glassHeight)/2
    },
    inoxW() {
      return this.doorWidth
    },
    inoxLX() {
      return this.showBg ?
        this.doorLeftWidth + this.inoxOffset :
        this.doorLeftWidth + this.leafOffset
    },
    inoxLW() {
      return this.glassX - this.inoxLX
    },
  }
}
</script>
